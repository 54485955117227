/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $swiperCategories = $('.swiper-categories'),
            $swiperProducts = $('.swiper-products'),
            $swiperImages = $('.swiper-images'),
            $categoryTags = $swiperCategories.find('[data-category-id]'),
            $loadedProductsContainer = $('.loaded-products .swiper-wrapper'),
            $btnMoreProducts = $('.btn-more-products'),
            limit = 15,
            catId = $('.page-content').data('cat-id'),
            lang = $('body').data('lang')
            ;


        /*
        |
        | Ajax States
        |--------------
        */
        let xhr = null;
        let state = {
            category_id: catId ? catId : 'all'
        }



        /*  
        |
        | Swiper categories
        |--------------------
        */
        const swiperCategories = new Swiper($swiperCategories.find('.swiper-container'), {
            slidesPerView: 'auto',
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

        /*  
        |
        | Swiper products
        |--------------------
        */
        var swiperProducts = new Swiper('.swiper-products .swiper-container', {
            slidesPerView: 3.2,
            slidesPerGroup: 3,
            spaceBetween: 15,
            // Responsive breakpoints
            breakpoints: {
                992: {
                    slidesPerView: 1.1,
                    slidesPerGroup: 1
                },
            },
            navigation: {
                nextEl: '.swiper-products .navigation .next',
                prevEl: '.swiper-products .navigation .prev',
            }
        })


        /*  
        |
        | Ajax Filters
        |---------------
        */
        $categoryTags.on('click', function (e) {
            e.preventDefault();
            const $btn = $(this);
            const id = $btn.data('category-id');
            let name = $btn.data('category-name');
            let desc = $btn.data('category-desc');
            let minPrice = $btn.data('min');
            let maxPrice = $btn.data('max');

            $categoryTags.removeClass('active');
            $btn.addClass('active');

            setCategoryId(id);

            $(".categoryTitle").text(name);
            $(".categoryDesc").text(desc);
            $(".categoryPrices .range").text("Entre " + minPrice + " et " + maxPrice);

            console.log(name)
            console.log(desc)

            loadProductsAjax(name);
        });


        /*  
        |
        | Ajax More
        |------------
        */
        $btnMoreProducts.on('click', function (e) {
            e.preventDefault();
            const $btn = $(this);
            const total = $loadedProductsContainer.find('.card-product').length;

            loadMoreProductsAjax(total, limit);
        });


        /*  
        |
        | Setters
        |----------
        */
        function setCategoryId(id) {
            state.category_id = id;
        }


        /*  
        |
        | Ajax Filters
        |---------------
        */
        function loadProductsAjax(name) {
            if (xhr !== null) {
                xhr.abort()
            }

            const route = `/ajax/products/category/${state.category_id}/${name}`;

            xhr = $.ajax({
                url: route,
                type: 'POST',
                dataType: 'json',
                data: {
                    lang: lang,
                },
                beforeSend: () => {
                    $("#loadingIcon").fadeIn();
                    $(".categoryTitle, .categoryDesc, .categoryPrices").hide();
                    $loadedProductsContainer.fadeOut(400, function () {
                        $(this).html('');
                    });
                },
                success: (response, status) => {
                    // console.log(response)
                    $loadedProductsContainer.html(response).fadeIn();
                    $("#loadingIcon").hide();
                    $(".categoryTitle, .categoryDesc, .categoryPrices").fadeIn();
                    TweenMax.staggerFrom($loadedProductsContainer.find('.card-product.loaded'), 0.8, { autoAlpha: 0, y: 30, ease: Power1.easeOut }, 0.1);
                    $loadedProductsContainer.find('.card-product.loaded').removeClass('loaded')
                    xhr = null;

                    swiperProducts.update();
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        }

        /*  
        |
        | Ajax More 
        |------------
        */
        function loadMoreProductsAjax(offset, limit) {
            if (xhr !== null) {
                xhr.abort()
            }

            const route = `/ajax/products/category/${state.category_id}/${offset}/${limit}`;

            xhr = $.ajax({
                url: route,
                type: 'POST',
                dataType: 'json',
                data: {
                    lang: lang,
                },
                beforeSend: () => {
                    $btnMoreProducts.addClass('loading')
                },
                success: (response, status) => {
                    $loadedProductsContainer.append(response);
                    TweenMax.staggerFrom($loadedProductsContainer.find('.card-product.loaded'), 0.8, { autoAlpha: 0, y: 30, ease: Power1.easeOut }, 0.1);
                    $btnMoreProducts.removeClass('loading')
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        }
    }
}

