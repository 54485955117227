/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs 
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper from 'swiper/dist/js/swiper.min';
import { TweenMax } from "gsap/TweenMax";
import SplitText from "@lib/gsap-pro/SplitText";
import ScrollTo from "gsap/ScrollToPlugin";
import Scrollbar from 'smooth-scrollbar';
import Plyr from 'plyr';

/*
|
| Importing Components
|-----------------------
*/
import CookieManager from './components/cookie-manager';
import customGoogleMap from './components/custom-google-map.js';
import Kira from './components/kira.js';
import Menu from './components/menu.js';
import MobileDetect from './components/mobile-detect.js';


/*
|
| Importing Utils
|-------------------
*/
import './utils/fa';
import Router from './utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from './components/global.js';
import main from './pages/main.js';
import home from './pages/home.js';
import contact from './pages/contact.js';
import landing from './pages/landing.js';
import catalogue from './pages/catalogue.js';
import product from './pages/product.js';
import blog from './pages/blog.js';

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
        'file': main,
        'dependencies': [app, CookieManager, MobileDetect, Kira, Menu]
    },
    {
        'file': home,
        'dependencies': [app, Plyr],
        'resolve': '#page-home'
    },
    {
        'file': contact,
        'dependencies': [app, customGoogleMap],
        'resolve': '#page-contact'
    },
    {
        'file': landing,
        'dependencies': [app, Swiper],
        'resolve': '#page-landing'
    },
    {
        'file': catalogue,
        'dependencies': [app, Swiper],
        'resolve': '#page-catalogue'
    },
    {
        'file': product,
        'dependencies': [app, Swiper],
        'resolve': '#page-single-product'

    },
    {
        'file': blog,
        'dependencies': [app],
        'resolve': '.pages-blog'
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

