/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, customGoogleMap) => {
        /*
        |
        | Constants
        |------------
        */
        const
            $map = $('#map')
        ;


        /*
		|
		| initGoogleMap
		|----------------
		|
		*/
        $.each($map, function () {
            var $map = $(this);

            var map = new customGoogleMap($map, {
                'markers': $map.find('.marker'),
                'zoom': 16
            });
        });


        /*
		|
		| initGoogleMap
		|----------------
		|
        */
        
        console.log('test')

        $(document).on('gform_page_loaded', '#gform_1', function (event, form_id, current_page) {
            console.log('gform_page_loaded')

        });

        $(document).on('gform_post_render', function (event, form_id, current_page) {
            console.log('gform_post_render')

        });

        $(document).on('gform_pre_render', function (event, form_id, current_page) {
            console.log('gform_pre_render')

        });

        
    }
}
