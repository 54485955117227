/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $swiperFrieze = $('.swiper-frieze'),
            $swiperImages = $('.swiper-images'),
            $slides = $swiperFrieze.find('.swiper-slide');
		;

		
		/*
        |
        | Swiper Frieze
        |----------------
        */
        $.each($swiperFrieze, function(){
            const $swiper = $(this);
            const $swiperContainer = $swiper.find('.swiper-container');

            const swiper = new Swiper($swiperContainer, {
                slidesPerView: 'auto',
                spaceBetween: 90,
                navigation: {
                    nextEl: $swiper.find('.arrow-right'),
                    prevEl: $swiper.find('.arrow-left'),
                },
                breakpoints:{
                    580:{
                        slidesPerView: 1
                    }
                }
            })
        });

        /*
        |
        | MouseOver
        |----------------
        */
        $slides.each(function(){
            const $card = $(this);
            const $description = $(this).find('.description');

            const timeline = new TimelineMax({ paused: true });
            timeline.from( $description.find('.item-short-descr'), 0.6, { y: 10, autoAlpha: 0, ease: Power1.easeOut });

            $card.on('mouseover', () => {
                timeline.play();
            })
            $card.on('mouseleave', () => {
                timeline.reverse();
            })
        })

        /*
        |
        | Swiper Images
        |----------------
        */
        $.each($swiperImages, function () {
            const $swiperContainer = $(this).find('.swiper-container');

            const swiper = new Swiper($swiperContainer, {
                slidesPerView: 'auto',
                speed: 1200,
                navigation: {
                    nextEl: $swiperImages.find('.arrow-right'),
                    prevEl: $swiperImages.find('.arrow-left'),
                },
                
            })
        });
	}
}

