/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, CookieManager, MobileDetect, Kira, Menu) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $body = $('body'),
            $affectedByMobile = $('body, .card-article'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse'),
            scrollAreas = document.querySelectorAll('.scroll-area'),
            $header = $('#header'),
            $headerLinks = $header.find('.menu-item-has-children'),
            $menuWrapper = $('#menu-container'),
            $menuUl = $menuWrapper.find('ul'),
            $menuLi = $menuUl.find('li'),
            $menuLinks = $menuLi.find('a'),
            $menuSocials = $menuWrapper.find('.item-socials'),
            $menuSocialsItems = $menuSocials.find('.item-social-link'),
            $menuBtn = $header.find('.btn-menu'),
            $menuBtnBars = $menuBtn.find('.item-burger'),
            $menuBtnBar = $menuBtnBars.find('> span'),
            $quantitySelector = $('.custom-quantity-select')
            ;


        /*
        |
        | Variables
        |------------
        */
        let isMobile = null;


        /*
        |
        | Mobile Detect
        |----------------
        */
        let mobileDetector = new MobileDetect();
        isMobile = mobileDetector.isMobile();
        if (isMobile == null) {
            $affectedByMobile.addClass('isDesktop');
        } else {
            $affectedByMobile.addClass('isMobile');
        }


        /*
        |
        | Scroll Areas
        |---------------
        */
        Array.prototype.forEach.call(scrollAreas, element => {
            Scrollbar.init(element);
        });


        /*
        |
        | Cookie Manager
        |-----------------
        */
        new CookieManager($cookieBanner, {
            name: 'adveris_cookie_use',
            duration: 30,
            buttons: {
                accept: $cookieAccept,
                refuse: $cookieRefuse,
                close: $cookieClose
            }
        });


        /*
        |
        | Menu
        |-------
        */
        const menu = new Menu($menuWrapper, $menuBtn, { reverseTimeScale: 1.5 });

        menu.menuTimeline
            .to($menuBtnBar.eq(1), 0.3, { autoAlpha: 0 }, 'start')
            .to($menuBtnBar.eq(0), 0.5, { x: 0, y: 8, rotation: 45, ease: Power1.easeOut }, 'start')
            .to($menuBtnBar.eq(2), 0.5, { x: 0, y: -8, rotation: -45, ease: Power1.easeOut }, 'start')
            .from($menuWrapper, 0.5, { autoAlpha: 0, ease: Power1.easeOut }, 'start+=0.3')
            .staggerFrom($menuLi, 0.6, { autoAlpha: 0, x: 20, ease: Power1.easeOut }, '0.1', '+=0')
            .staggerFrom($menuSocialsItems, 0.6, { autoAlpha: 0, x: 20, ease: Power1.easeOut }, '0.1', '-=0.4')

        menu.init();

        $menuWrapper.on('menu:open', () => {
            $menuWrapper.addClass('opened');
            $body.addClass('o-h');
        });

        $menuWrapper.on('menu:closed', () => {
            $menuWrapper.removeClass('opened');
            $body.removeClass('o-h');
        });


        /*
        |
        | Header submenus
        |------------------
        */
        $.each($headerLinks, function () {
            const $link = $(this);
            const $submenu = $link.find('.submenu');
            const $links = $submenu.find('li');
            const timeline = new TimelineMax({ paused: true });

            timeline.to($submenu, 0.5, { autoAlpha: 1, scaleY: 1, ease: Power1.easeOut }, '0.1', 'start');
            timeline.staggerFrom($links, 0.5, { autoAlpha: 0, x: 15, ease: Power1.easeOut }, '0.1', '-=0.2');

            $link.on('mouseover', () => {
                timeline.play().timeScale(1);
            }).on('mouseleave', () => {
                timeline.reverse().timeScale(2);
            })
        });


        /*
        |
        | header visibility
        |--------------------
        */
        let prevScrollTop = 0;

        // $(window).on('scroll', function (e) {
        //     const scrollTop = $(this).scrollTop();

        //     handleHeaderVisibility(scrollTop, prevScrollTop, 100);
        //     prevScrollTop = scrollTop;
        // });


        /*
        |
        | quantity selector
        |--------------------
        */
        $.each($quantitySelector, function () {
            const $bloc = $(this);
            const $input = $bloc.find('input.qty');
            const $custom = $bloc.find('.item-custom-select');
            const $minus = $custom.find('.item-minus');
            const $plus = $custom.find('.item-plus');
            const $value = $custom.find('.item-value');
            const min = $input.attr('min') ? $input.attr('min') : 1;

            $plus.on('click', function () {
                const val = parseInt($input.val());
                updateQty($input, $value, parseInt($input.val()) + 1)
            });

            $minus.on('click', function () {
                const val = parseInt($input.val());
                if (val > min) {
                    updateQty($input, $value, parseInt($input.val()) - 1)
                }

            });
        });

        function updateQty($input, $fake, newVal) {
            $input.val(newVal);
            $fake.text(newVal);
            $input.trigger('change');

            $('.btn-update-cart').prop('disabled', false)
            $('.btn-update-cart').removeAttr("disabled");
        }

        $(window).on('load', () => {
            $('.btn-update-cart').removeClass('onload-hide')
        })



        /*
        |
        | handleHeaderVisibility
        |-------------------------
        */
        function handleHeaderVisibility(scrollTop, prevScrollTop, offset) {
            if (scrollTop < offset) {
                $header.replaceClass('scrolled', 'not-scrolled');
            } else {
                $header.replaceClass('not-scrolled', 'scrolled');
            }

            if (prevScrollTop < scrollTop) {
                $header.replaceClass('scrolling-up', 'scrolling-down');
            } else {
                $header.replaceClass('scrolling-down', 'scrolling-up');
            }
        }


        let hideInfoBanner = sessionStorage.getItem('hide_info_banner');
        
                if (!hideInfoBanner) {
                    console.log('show');
                    infoBannerShow();
                }
        
                $('#popup-banner').on('click', '.popupBanner-close', function() {
                    infoBannerHide();
        
                    sessionStorage.setItem('hide_info_banner', true);
                });
        
                function infoBannerShow() {
                    let $banner = $('#popup-banner');
        
                    $banner.css('display', 'flex');
                }
        
                function infoBannerHide() {
                    let $banner = $('#popup-banner');
        
                    $banner.css('display', 'none');
                }

                // get all button with data-newsletter
        const $newsletterBtn = document.querySelectorAll('[data-newsletter]');
        console.log('here');
        // add event listener to each button
        if ($newsletterBtn.length > 0) {
            $newsletterBtn.forEach((btn) => {
                console.log('click');
                btn.addEventListener('click', (e) => {
                    e.preventDefault();
                    document.getElementById('popup').classList.add('active');
                });
            });
        }
        document.getElementById('popup-close').addEventListener('click', () => {
            document.getElementById('popup').classList.remove('active');
        });
    }
}
