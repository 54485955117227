/*** IMPORTS FROM imports-loader ***/
var define = false;

import { library, dom } from '@fortawesome/fontawesome-svg-core/index';
import { 
    faFacebookF,
    faTwitter
} from '@fortawesome/free-brands-svg-icons'; // prefix: fab
import { faFire as fasFaFire } from '@fortawesome/pro-solid-svg-icons'; // prefix: fas
import { faFire as farFaFire } from '@fortawesome/pro-regular-svg-icons'; // prefix: far
import { faTimes as falTimes } from '@fortawesome/pro-light-svg-icons'; // prefix: fal

library.add(
    faFacebookF,
    faTwitter,
    falTimes,
    farFaFire, 
    fasFaFire, 
)
dom.watch();
