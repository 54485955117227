/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper) => {
        /*
        |
        | Constants
        |-----------
        */
        const
            $loadedArticlesContainer = $('.loaded-articles'),
            $gridProductLoader       = $('.grid-product-loader'),
            $btnMoreArticles         = $('.btn-more-articles'),
            limit                    = 3,
            $cardArticle             = $('.card-article'),
            lang = $('body').data('lang')
        ;


        /*
		|
		| Ajax States
		|--------------
        */
        let xhr = null;


        /*
		|
		| Card articles
		|-------------------------
        */
        if ($cardArticle.length){
            initCardArticleHover($cardArticle)
        }

        function initCardArticleHover($cardArticle) {
            $.each($cardArticle, function(){
                const $card = $(this);
                const $content = $card.find('.item-content');
                const timeline = new TimelineMax({ paused: true });

                timeline
                    .from($content, 0.6, { scaleY: 0, transformOrigin: 'left bottom', ease: Expo.easeOut }, 'start')
                    .from($content.find('.stagger-item'), 0.6, { y: 10, autoAlpha: 0, ease: Power1.easeOut }, '-=0.3')

                $card.on('mouseover', () => {
                    timeline.play().timeScale(1);
                }).on('mouseleave', () => {
                    timeline.reverse().timeScale(1.5);
                })
            });
        }


        /*  
        |
        | Ajax More
        |------------
        */
        $btnMoreArticles.on('click', function (e) {
            e.preventDefault();
            const $btn = $(this);
            const total = $loadedArticlesContainer.find('.card-article').length;

            loadMoreArticlesAjax(total, limit);
        });
        

        /*  
        |
        | Ajax More 
        |------------
        */
        function loadMoreArticlesAjax(offset, limit) {
            if (xhr !== null) {
                xhr.abort()
            }
            
            const route = `/ajax/articles/${offset}/${limit}`;

            xhr = $.ajax({
                url: route,
                type: 'POST',
                dataType: 'json',
                data: {
                    lang: lang,
                },
                beforeSend: () => {
                    $btnMoreArticles.addClass('loading')
                },
                success: (response, status) => {
                    $loadedArticlesContainer.find('.card-article.loaded').removeClass('loaded');
                    $loadedArticlesContainer.append(response);
                    initCardArticleHover($loadedArticlesContainer.find('.card-article.loaded'))
                    TweenMax.staggerFrom($loadedArticlesContainer.find('.card-article.loaded'), 0.8, { autoAlpha: 0, y: 30, ease: Power1.easeOut }, 0.1);
                    $btnMoreArticles.removeClass('loading')
                    xhr = null;
                },
                error: (response, status, error) => {
                    console.log(response, status, error);
                }
            })
        }
    }
}

