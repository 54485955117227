/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Plyr) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $sectionVideo = $('.section-video'),
			$videoContainer = $sectionVideo.find('.video-container')
		;

		
		/*
        |
        | Video
        |--------
        */
        if ($videoContainer.length) {
            const player = new Plyr('#home-video', {
                //controls: [],
                autoplay: true,
                muted: true,
                loop: {
                    active: true
                }
            });

            player.on('play', event => {
                $('.plyr__controls').css({
                    'display': 'flex'
                });
            });
            
        }
	}
}

