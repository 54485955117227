/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Swiper) => {
		/*
		|
		| Constants
		|-----------
		*/
        const 
            $swiperGallery = $('.swiper-gallery'),
            $swiperGalleryContainer = $swiperGallery.find('.swiper-container');
		;

        /*
        |
        | Swiper Images
        |----------------
        */
       console.log('tu')
        const swiper = new Swiper($swiperGalleryContainer, {
            slidesPerView: 1,
            speed: 1200,
            navigation: {
                nextEl: $swiperGallery.find('.arrow-right'),
                prevEl: $swiperGallery.find('.arrow-left'),
            }
        })
	}
}

